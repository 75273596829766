.beta-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .button {
    margin-top: var(--ye-spacing-rem-large);
  }
}

.title {
  font-size: var(--ye-font-size-large);
  font-weight: 600;
  line-height: var(--ye-spacing-rem-xlarge);
  color: var(--color-ink-l2);
}

.body {
  margin-top: var(--ye-spacing-rem-xlarge);
  color: var(--color-blue-c2-d2);
}

.blog-button {
  @mixin focusAndHover {
    color: var(--color-canvas);
  }

  margin-top: var(--ye-spacing-rem-xlarge);
  padding: var(--ye-button-padding);

  font-weight: 600;
  color: var(--color-canvas);

  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: var(--ye-font-size-xlarge);
}
